import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import * as styles from "./NotFound.module.scss";

import image from "../../images/atharva-tulsi-Uv2BaNZjjvY-unsplash.jpg";

const NotFound = () => {
    return (
        <div
            className={styles.container}
            style={{ backgroundImage: "url(" + image + ")" }}
        >
            <Helmet
                title={"404: Page Not Found"}
                description="This page could not be found."
            ></Helmet>
            <div className={styles.inner}>
                <span className={styles.title}>404: Page Not Found</span>
                <p className={styles.text}>
                    Sorry, but we could not find the page you were looking for.
                    Please go <Link to={"/"}>back to the homepage</Link>.
                </p>
            </div>
        </div>
    );
};

export default NotFound;
