import * as React from "react";
import Layout from "../layout/default";
import NotFound from "../components/NotFound/NotFound";

const NotFoundPage = () => {
    return (
        <Layout noSpaceBehindNav={true}>
            <NotFound />
        </Layout>
    );
};

export default NotFoundPage;
